import { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { AppStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { ObixUtil } from '../service/ObixUtil';

export function Events(props) {
    // props.site
    // props.siteInfo
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [tick, setTick] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [date, setDate] = useState(null);  
    const [start, setStart] = useState(null);  // history 요청할 시작 날짜. (XML Date)
    const [end, setEnd] = useState(null);  // history 요청할 시작 날짜. (XML Date)
    const [category, setCategory] = useState("");
    const cal = useRef(null);  // calendar 
    const title = <span>
        <h5 className="m-0"><i className="pi pi-folder-open"></i> Query Events</h5>
    </span>;

    const onReset = () => {
        setKeyword("");
        setDate(null);
        setStart(null);
        setEnd(null);
        setCategory("");
        setTick((prev) => prev+1);
    }

    const onHideCalendar = () => {
        //console.log(date);
    }

    const handleKeyPressed = (e) => {
        //console.dir(e);
        if (e.key === "Enter") {
            console.log("Enter key pressed");
            //setKeyword(typing);
            setTick((prev) => prev+1);
        }
    }

    // date state가 바뀌면 start/end도 바꿈. 
    useEffect(() => {
        //console.log(`Changed? ${date[0]} - ${date[1]}`);
        // 하나 선택할 때는 두번째 항목은 null로 날아옴 
        // 하나 선택할 때는 동작않게 하자. 
        if (date == null) return;
        if (date[0] == null) return;
        const s = new Date(date[0].getFullYear(), date[0].getMonth(),date[0].getDate(), 0, 0, 0, 0);
        setStart(s);
        /*
        if (!date[1]) {  // 앞 날짜만 들어온 불완전한 상태이면 1일만 한다. 
            const e = new Date(date[0].getFullYear(), date[0].getMonth(), date[0].getDate()+1, 0, 0, 0, 0);
            setEnd(e);
        }*/
        if (date[1] == null) return;
        const e = new Date(date[1].getFullYear(), date[1].getMonth(), date[1].getDate()+1, 0, 0, 0, 0);
        setEnd(e);
        //cal.current.visible = false;
        // https://github.com/primefaces/primereact/blob/a01a0beb9850137ead492a269fcc6a79f6c0062d/components/lib/calendar/Calendar.js#L1372
        cal.current.hide();  // Undocument지만, 소스코드에서 찾음. 
        //cal.current.hideOverlay();  // 문서화되지 않았지만... 이게 먹는다. 
        //console.log(`start=${s}, end=${e}`);
    }, [date]);

    const categoryItems = [
        {label: 'Any Category', value: ''},
        {label: 'FAULT', value: 'FAULT'},
        {label: 'WARNING', value: 'WARNING'},
        {label: 'INFO', value: 'INFO'}
    ];

    const eventsTable = useMemo(() => {
        return <EventsTable keyword={keyword} start={start} end={end} category={category} tick={tick} site={props.site}/>;
    }, [tick, appState.mmurl, props.site]); 

    return (
        <Card title={title}>
            <div>
                <label className="mr-2">Search For </label>
                <Calendar id="date" ref={cal} selectionMode="range" value={date} onChange={(e) => setDate(e.value)} onHide={onHideCalendar} dateFormat="yy.m.d" 
                    className="mr-2" placeholder="Select date range"/>
                <Dropdown className="mr-2" value={category} options={categoryItems} onChange={(e) => setCategory(e.value)} placeholder="Category" style={{width:"150px"}}/>
                <InputText className="mr-2" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Keyword" onKeyPress={handleKeyPressed}/>
                <Button className="mr-2" label="Search" onClick={(e) => setTick((prev) => prev+1)}/>
                <Button className="mr-2 p-button-secondary" label="Reset" onClick={onReset}/> 
            </div>
            <br/>
            {eventsTable}
        </Card>
    );
}

function EventsTable(props) {
    //props.keyword: 검색어 
    //props.site
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [offset, setOffset] = useState(0);
    const [events, setEvents] = useState([]);
    const [rows, setRows] = useState(25);
    const [totalRows, setTotalRows] = useState(500);
    const toast = useRef(null);

    useEffect(() => {
        setOffset(0);  // 검색어가 바뀌면 앞으로 돌아가기 
    }, [props.keyword, props.tick, props.site]);

    useEffect(() => {        
        //console.log(`load entered: ${appContext.sgurl}`);
        if (!appState.mmurl) return;
        if (!props.site) return;
        const limit = 25;
        let invokeUrl = appState.mmurl + "/obix/histories/events/query";
        let body = {
            o: 'obix',
            c: [
                { o: "int", name: "limit", val: limit }, 
                { o: "int", name: "offset", val: offset },
                { o: "bool", name: "reverse", val: true }, 
                { o: "str", name: "key", val: props.keyword },
                { o: "abstime", name: "start", val: ObixUtil.toIsoDate(props.start) },
                { o: "abstime", name: "end", val: ObixUtil.toIsoDate(props.end) },
                { o: "str", name: "category", val: props.category },
                { o: "str", name: "site", val: props.site }
            ]
        }
        ObixUtil.invokeObix(invokeUrl, body, 
            (resp) => {
                // -> data(list) -> c
                // 결과 보여주는 창 띄우기 
                //onsole.log("Events loaded");
                let childs = ObixUtil.find(resp.data, "data").c;
                const total = ObixUtil.find(resp.data, "total")?.val;
                if (total != undefined) setTotalRows(total);
                else setTotalRows(1000);
                let events = [];
                if (childs) {
                    for (const c of childs) {
                        let item = { 
                            eid: ObixUtil.find(c, "eid")?.val, 
                            geid: ObixUtil.find(c, "geid")?.val,
                            edge: ObixUtil.find(c, "edge")?.val,
                            ttime: ObixUtil.find(c, "when")?.val, 
                            source: ObixUtil.find(c, "src")?.val, 
                            category: ObixUtil.find(c, "cat")?.val, 
                            detail: ObixUtil.find(c, "msg")?.val,  
                        }
                        events.push(item);
                    }
                }
                //console.dir(events);
                setEvents(events);
            },
            (errmsg) => {
                toast.current.show({ severity: 'error', summary: 'Failed to get events', detail: errmsg, life: 10000 });
            }
        );
    }, [appState.mmurl, offset, props.keyword, props.start, props.end, props.category, props.tick, props.site]);

    const categoryTemplate = (rowData) => {
        let catStyle = {}
        if (["ERROR", "FAULT"].includes(rowData.category)) {
            catStyle = {color: "red", fontWeight: "bold"};
        } else if (["WARNING", "WARN"].includes(rowData.category)) {
            catStyle = {color: "orange", fontWeight: "bold"};
        }
        return <span style={catStyle}>{rowData.category}</span>
    }

    const onPageChange = (event) => {
        console.log(`first=${event.first}, rows=${event.rows}`);
        setOffset(event.first);
        setRows(event.rows);
    }

    return (
        <div>
            <DataTable value={events} resizableColumns columnResizeMode="fit" showGridlines autoLayout={true} size="small" responsiveLayout="scroll">
                <Column field="eid" header="ID" style={{width: "80px"}} ></Column>
                <Column field="geid" header="ID(GW)" style={{width: "80px"}} ></Column>
                <Column field="edge" header="Edge" style={{width: "80px"}} ></Column>
                <Column field="ttime" header="Time" style={{width: "220px"}} ></Column>
                <Column field="category" header="Category" body={categoryTemplate} style={{width: "100px"}} ></Column>
                <Column field="source" header="Source" style={{width: "300px"}} ></Column>
                <Column field="detail" header="Details"></Column>
            </DataTable>
            <Paginator first={offset} rows={rows} totalRecords={totalRows} onPageChange={onPageChange} pageLinkSize={9}/>
            <Toast ref={toast}></Toast>
        </div>
    );
}