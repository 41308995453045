import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AppStateAtom, ColorModeAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import ReactECharts from 'echarts-for-react';
import 'primeflex/primeflex.css';
import { ObixUtil } from '../service/ObixUtil';
import { jsonToCsv } from '../service/CsvUtil';
import dateFormat from "dateformat";

export function Trends(props) {
    //props.site 
    //props.siteInfo
    //const appContext = useContext(AppContext);
    const [selected, setSelected] = useState("");

    const setUrl = useCallback((url) => {
        setSelected(url);
    }, []);

    const charts = useMemo(() => {
        return <Charts url={selected}/>;
    }, [selected]); 

    const deviceNodes = useMemo(() => {
        return <DeviceNodes onSelected={setUrl} siteInfo={props.siteInfo}/>;
    }, [selected, props.siteInfo]); 

    return (
        <div className="grid">
            <div className="col-12 md:col-9">
                {charts}
            </div>
            <div className="col-12 md:col-3">
                {deviceNodes}
            </div>
        </div>
    );
}

export function DeviceNodes(props) {
    // props.siteInfo: siteInfo 
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    //const [urls, setUrls] = useState([]);
    const [urlModel, setUrlModel] = useState([]);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        console.dir(props.siteInfo);
        if (!props.siteInfo.edges) return;
        const edges = props.siteInfo.edges.join(',');
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: edges }
            ]
        }
        ObixUtil.invokeObix(appState.mmurl + '/obix/histories/getUrls', body, 
            (resp) => {
                const a = [];
                for (const c of resp.data.c) {
                    a.push({
                        label: c.val,  
                    });
                }
                setUrlModel(a);
                if (a.length > 0) {
                    setUrl(a[0]);
                    props.onSelected(a[0].label);
                }
            }, 
            (err) => {
                console.error(err);
            }
        );
    }, [appState.mmurl, props.siteInfo]);

    function onListSelect(e) {
        setUrl(e.value);
        //console.dir(e);
        props.onSelected(e.value?.label);
    }

    function itemTemplate(option) {
        //console.dir(option);
        return <div>
                <i className="pi pi-caret-right"/> 
                <span>{option.label}</span>
            </div>;
    }

    const title = <span>
        <h5 className="p-m-0">
            <i className="pi pi-eye"></i> 
            <span> Choose Device </span>
            {/*<ToggleButton checked={pinned} onChange={onChangePin} onLabel="" offLabel="" onIcon="pi pi-paperclip" offIcon="pi pi-paperclip" className="narrow-toggle-button" />*/}
        </h5>
    </span>;

    return (
        <Card title={title}>
            {/*<Menu model={urlModel} className="width-100"/>*/}
            <ListBox optionLabel="label" options={urlModel} value={url} onChange={onListSelect} itemTemplate={itemTemplate} className="narrow"/>
        </Card>
    );
}

export function Charts(props) {
    // props.url = 선택된 노드의 URL = port/addr/obj/url@obd  
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [edge, setEdge] = useState("");
    const [url, setUrl] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [date, setDate] = useState([new Date(), new Date()]);  
    const [chartMeta, setChartMeta] = useState({});  // device의 charts 정보를 담음 
    const [chartData, setChartData] = useState([]);  // chart data 
    const [chartWidgets, setChartWidgets] = useState([]);  // chart widgets
    const [columns, setColumns] = useState([]);  // DataTable의 column 들 
    const [start, setStart] = useState(null);  // history 요청할 시작 날짜. (XML Date)
    const [end, setEnd] = useState(null);  // history 요청할 시작 날짜. (XML Date)
    const [loading, setLoading] = useState(false);  // loading icon 보여줄건가 
    const toast = useRef(null);
    const dt = useRef(null);
    const cal = useRef(null);  // calendar 

    useEffect(() => {
        if (!props.url) return;
        const words = props.url.split('@');  // props.url 은 port/1/url@edge 형식임 
        if (words.length < 2) return; 

        setEdge(words[1]);
        setUrl(words[0]);    
    }, [props.url]);

    useEffect(() => {
        if (!edge || !url) return;
        // 해당 노드의 accumulation 메타 정보 가져오기 
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "edge", val: edge }, 
                { o: "str", name: "uri", val: url },
            ]
        }

        ObixUtil.invokeObix(appState.mmurl + '/obix/histories/getDeviceInfo', body, 
            (resp) => {
                const charts = ObixUtil.find(resp.data, "charts");
                if ('val' in charts) {  // val 필드가 있는지 체크 
                    setChartMeta(JSON.parse(charts.val));
                } else {
                    setChartMeta({});
                }
                setDeviceName(ObixUtil.find(resp.data, "deviceName")?.val + "@" + edge);
            }, 
            (err) => {
                console.error(err);
            }
        );
    }, [edge, url, appState.mmurl])

    // date state가 바뀌면 start/end도 바꿈. 
    useEffect(() => {
        //console.log(`Changed? ${date[0]} - ${date[1]}`);
        // 하나 선택할 때는 두번째 항목은 null로 날아옴 
        // 하나 선택할 때는 동작않게 하자. 
        if (date[0] === null || date[1] === null) return;
        const s = new Date(date[0].getFullYear(), date[0].getMonth(),date[0].getDate(), 0, 0, 0, 0);
        setStart(s);
        /*
        if (!date[1]) {  // 앞 날짜만 들어온 불완전한 상태이면 1일만 한다. 
            const e = new Date(date[0].getFullYear(), date[0].getMonth(), date[0].getDate()+1, 0, 0, 0, 0);
            setEnd(e);
        }*/
        const e = new Date(date[1].getFullYear(), date[1].getMonth(), date[1].getDate()+1, 0, 0, 0, 0);
        setEnd(e);
        //cal.current.visible = false;
        // https://github.com/primefaces/primereact/blob/a01a0beb9850137ead492a269fcc6a79f6c0062d/components/lib/calendar/Calendar.js#L1372
        cal.current.hide();  // Undocument지만, 소스코드에서 찾음. 
        //cal.current.hideOverlay();  // 문서화되지 않았지만... 이게 먹는다. 
        //console.log(`start=${s}, end=${e}`);
    }, [date]);
    
    // queryTable 로 데이터 읽어오기 
    useEffect(() => {
        if (!edge || !url) return;
        let invokeUrl = appState.mmurl + '/obix/histories/history/queryTable';
        let body = {
            o: 'obix',
            c: [
                { o: 'abstime', name: "start", val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: "end", val: ObixUtil.toIsoDate(end) },
                { o: 'str', name: 'uri', val: url },
                { o: 'str', name: 'edge', val: edge }  
            ]
        }
        //console.dir(body);
        setLoading(true);
        const makeTitle = (f) => {
            let s = f.replace(/([^A-Z])([A-Z])/g, '$1 $2');
            s = s.charAt(0).toUpperCase() + s.slice(1);
            if (s.startsWith('Dc ')) {
                s = "DC " + s.slice(3);
            } else if (s.startsWith('Ac ')) {
                s = "AC " + s.slice(3);
            }
            return s;
        }
        ObixUtil.invokeObix(invokeUrl, body, 
            (resp) => {
                for (let i = 0; i < resp.data.length; i++) {
                    for (const prop in resp.data[i]) {
                        if (typeof resp.data[i][prop] === 'number') {
                            resp.data[i][prop] = Math.round(resp.data[i][prop]*1000.0)/1000.0;
                        } else if (prop === "ttime") {
                            // 2023-09-11T00:00:00 에서 T를 없앤다. 
                            resp.data[i][prop] = resp.data[i][prop].replace(/T/g, ' ');
                        }
                    }
                }
                setChartData(resp.data);
                const c = [];
                for (const item of resp.data) {
                    if ("__error__" in item) {
                        continue;
                    } else {
                        for (const field in item) {
                            {/* Camel Case splitting */}
                            if (field === "ttime") {
                                c.push(<Column key={field} field={field} header="Time" style={{ flexGrow: 1, flexBasis: '160px' }}/>);                            
                            } else {
                                c.push(<Column key={field} field={field} header={makeTitle(field)} 
                                    style={{ flexGrow: 1, flexBasis: '100px' }}/>);                            
                            }
                        }
                        break;
                    }
                }
                setColumns(c);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                setLoading(false);
            }
        );
    }, [start, end, edge, url, appState.mmurl])

    // Chart 객체를 새로 만든다. 
    useEffect(() => {
        const charts = [];
        for (const key in chartMeta) {            
            charts.push(
                <GenericChart key={key} title={key} fields={chartMeta[key]} table={chartData}/>
            );
        }
        setChartWidgets(charts);
    }, [chartData, chartMeta]);

    const onPrev = (e) => {        
        // setDate((prev) => new Date(prev.setDate(prev.getDate()-1)))
        setDate(prev => {
            const d = new Date(prev[0]);
            d.setDate(d.getDate()-1);
            return [d, d];
        });
    }

    const onNext = (e) => {
        setDate(prev => {
            const d = new Date(prev[0]);
            d.setDate(d.getDate()+1);
            return [d, d];
        });
    }

    const exportCsv = () => {
        //dt.current.exportCSV();
        const d = dateFormat(start, "yyyy-mm-dd");
        jsonToCsv(chartData, `${edge}-${url}-${d}-trend`, true);
    };

    const onHideCalendar = () => {
        //console.log(date);
    }

    const dtHeader = <div style={{textAlign:'right'}}>
        <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={exportCsv}></Button>
    </div>;

    const title = <span>
        <h5 className="p-m-0"><i className="pi pi-chart-bar"></i> Charts for "{url}" ({deviceName})</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="flex justify-content-between">
                <div className="flex align-items-center">
                    <label htmlFor="date" className="mx-1">Choose Date: </label>
                    <Calendar id="date" ref={cal} selectionMode="range" value={date} onChange={(e) => setDate(e.value)} onHide={onHideCalendar} dateFormat="yy.m.d" className="mx-1"/>
                    <Button icon="pi pi-angle-left" className="mx-1" onClick={onPrev}/>
                    <Button icon="pi pi-angle-right" className="mx-1" onClick={onNext}/>
                    <Button label="Today" className="mx-1" onClick={(e) => setDate([new Date(), new Date()])}/>
                    { loading ? <i className="pi pi-spin pi-spinner ml-2 font-bold" style={{'fontSize': '1.5em', 'color': 'gray'}}/> : null }
                </div>
            </div>
            <hr/>
            <TabView>
                <TabPanel header="Charts">
                    <div>
                        {chartWidgets}
                    </div>
                </TabPanel>
                <TabPanel header="Table">
                    <DataTable value={chartData} header={dtHeader} ref={dt} size="normal" showGridlines={false} stripedRows={false} 
                        scrollable scrollDirection="both" scrollHeight="600px" virtualScrollerOptions={{ itemSize: 46 }}>
                        {columns}
                    </DataTable>
                </TabPanel>
            </TabView>  
            <Toast ref={toast}></Toast>   
        </Card>
    )
}

export function GenericChart(props) {
    // props.title:  Title@unit 형태. 예를 들어 Voltage@kW 
    // props.fields:  fields 배열 
    // props.table:  table 형태 
    //const colorModeContext = useContext(ColorModeContext);
    const colorMode = useRecoilValue(ColorModeAtom);
    const [title, setTitle] = useState("???");
    const chartMemo = useMemo(() => {
        // props.fields 가 object로 날아옴. 
        if (!props.fields || !props.table) {
            console.warn("props.table is empty?");
            return null;
        }

        const words = props.title.split('@');  // 이름과 단위 분리 
        setTitle(words[0]);
        let yName = null;
        if (words.length > 1) {
            yName = words[1];
        }

        const series = [];
        const legends = [];
        for (const field of props.fields) {
            const data = [];
            for (const item of props.table) {
                if (field in item)
                    data.push([item.ttime, item[field]]);
            }
            if (data.length > 0) {
                series.push({
                    name: field,
                    type: 'line', 
                    showSymbol: false,
                    data: data
                });
                legends.push(field);
            }
        }

        const options = {
            xAxis: {
                type: 'time',
                name: 'Time',
                splitLine: {
                    show: false
                }, 
            },
            yAxis: {
                type: 'value',
                name: yName,
                splitLine: {
                    show: false
                },
            },
            legend: {
                data: legends
            },
            tooltip: {
                trigger: 'axis',
            },    
            toolbox: {
                feature: {
                    /*
                    saveAsImage: {},
                    dataView: {
                        optionToContent: exportCsv
                    },
                    */
                    dataZoom: {
                        show: true
                    }
                },
                top: 20
            },    
            series: series
        };

        if (series.length > 0) {
            return <ReactECharts option={options} notMerge={true} style={props.cstyle} opts={{ renderer: 'canvas' }} theme={colorMode}/>;
        } else {
            return null;
        }
    }, [props.cstyle, props.fields, props.table, props.title, colorMode]); 

    return (
        <div>
            {chartMemo ? <h5>{title}</h5>: null}
            {chartMemo}
            <br/>
        </div>
    );
}
