import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { RecoilRoot, useRecoilState } from 'recoil';
import { AuthStateAtom, AppStateAtom } from './atoms';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import jwt_decode from "jwt-decode";
import { useCookieStorage } from './service/useCookieStorage';

//export const RTLContext = React.createContext();
//export const AuthContext = React.createContext();
// Context에 대한 껍데기만 두는 곳이다. 
/*
export const AppContext = React.createContext({
    mmurl: null, 
    brand: null,
    edge: null,
    setSgurl: (url) => {}
});
*/
/**
 * Login 같이 전체화면을 차지하는 경우의 Logo 임. 
 * @param {*} props 
 * @returns 
 */
export function AppLogoEntry(props) {
    const hstyle = {
        align: "left",
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "2em",
        margin: 0,
        color: "White",
        textShadow: "2px 2px 2px black"
    }

    return (
        <div>
            <img src="android-chrome-192x192.png"/>
            <span id="app-logo" className="mx-2" style={hstyle}>Mastermind</span>
        </div>
    );
}

export const AppWrapper = (props) => {
    //const [appContext, setAppContext] = useState({mmurl: null});  // appContext
    const [appState, setAppState] = useRecoilState(AppStateAtom);
    const [auth, setAuth] = useRecoilState(AuthStateAtom); //useState({user: null, role: null});  // authContext 
    //const [isRTL, setRTL] = useState(false);
    const [mmToken, setMmToken] = useCookieStorage('mm_token', '');
    //const [mmUrl, setMmUrl] = useCookieStorage('mmurl', '');

    // AuthContext를 만든다. 
    //NOTE: 향후 cookie로 바꾸기?
    //const token = window.localStorage["mm_token"];
    useEffect(() => {
        try {
            //console.log(`mmToken=${mmToken}`);
            const decoded = jwt_decode(mmToken);
            //console.log(`Decoded ===> ${JSON.stringify(decoded)}`);
            const a = {
                user: decoded["sub"],  // Subject 
                role: decoded["auth"],  // Authorities
                isAdmin: decoded["auth"] === "admin", 
                isUser: ["admin", "user"].includes(decoded["auth"]),
                authenticated: ["admin", "user", "viewer"].includes(decoded["auth"])
            };
            setAuth(a);
        } catch (e) {
            // token이 invalid한 경우 
            setAuth({user: null, role: null, isAdmin: false, isUser: false, authenticated: false});
        }
    }, [mmToken]);
    // sgurl을 가져옴. localStorage에 저장 또는 현재 URL에서 추출 
    // 더불어 services/ess에 있는 bmsUri 등을 세팅함. 
    //const storedMmurl = window.localStorage["mmurl"];
    /*
    // AppStateAtom 으로 옮김 
    useEffect(() => {
        let url = mmUrl;
        if (!url) {
            url = "http://" + document.location.host;
        }
        //console.log(`MMURL = ${url}, ${mmUrl}`);
        setAppState(prevState => ({
             ...prevState, 
             mmurl: url
        }));
    }, [mmUrl]);
    */
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/error" element={<Error />} />
                <Route path="/notfound" element={<NotFound />} />
                <Route path="*" element={<App />} />
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <AppWrapper/>
        </RecoilRoot>
    </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
