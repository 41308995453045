import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { ConfirmDialog } from 'primereact/confirmdialog'; // To use <ConfirmDialog> tag
//import { AuthContext } from '../index';
//import { AppContext } from '../index';
import { AppStateAtom } from '../atoms';
import { ObixUtil } from '../service/ObixUtil';
import { AuthStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';

export function AdminUsers(props) {
    //const authContext = useContext(AuthContext);
    const auth = useRecoilValue(AuthStateAtom);
    if (auth?.isAdmin) {
        return (
            <UserCard/>
        );
    } else {
        return (
            <div>You do not have access to this page.</div>
        );    
    }
}

function UserCard(props) {
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedContext, setSelectedContext] = useState(null);  // context menu 선택 
    const [tick, setTick] = useState(null);  // 사용자 추가시 리스트 새로가져오게 하기 위함임. 
    const [showNew, setShowNew] = useState(false);
    const [showMessage, setShowMessage] = useState("hidden");
    const [message, setMessage] = useState("");
    const [editMode, setEditMode] = useState(true);  // 한 Dialog를 공유하기 위해, true이면 편집모드, false이면 Add New Mode 
    const [userId, setUserId] = useState("");
    const [role, setRole] = useState("viewer");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [region, setRegion] = useState("");
    const toast = useRef(null);
    const cm = useRef(null);  // context menu

    const roleItems = [
        {label: 'Viewer', value: 'viewer'},
        {label: 'User', value: 'user'},
        {label: 'Admin', value: 'admin'}
    ];

    const menuModel = [
        {label: 'Disable/Enable', icon: 'pi pi-fw pi-check-square', command: () => activateUser(selectedContext)},
        {label: 'Un/Subscribe', icon: 'pi pi-fw pi-inbox', command: () => subscribeUser(selectedContext)},
        //{label: 'Check', icon: 'pi pi-fw pi-eye', command: () => {}},
        {label: 'Edit', icon: 'pi pi-fw pi-id-card', command: () => editUser(selectedContext)},
        //{label: 'Reset Password', icon: 'pi pi-fw pi-undo', command: () => resetPassword(selectedContext)},
        //{label: 'Force Logout', icon: 'pi pi-fw pi-sign-out', command: () => forceLogout(selectedContext)},
        //{label: 'Delete', icon: 'pi pi-fw pi-times', command: () => deleteUser(selectedContext)}
    ];

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setShowNew(false)} className="p-button-text" />
                <Button label={editMode===true ? "Update" : "Create"} icon="pi pi-check" onClick={() => onCreateOrUpdateUser()} autoFocus />
            </div>
        );
    }

    // 현재 사용자 리스트를 읽어옴 
    useEffect(() => {
        let getUsersUrl = appState.mmurl + `/obix/admin/getUsers`;
        ObixUtil.invokeObix(getUsersUrl, {}, // 일단은 빈 body 요청이다 
            (resp) => {
                //console.dir(resp.data);
                const uList = [];
                const userList = ObixUtil.find(resp.data, "userList");
                if (userList && userList.c) {                    
                    for (const u of userList.c) {
                        const umap = ObixUtil.cmap(u);
                        //console.dir(umap);
                        const user = {
                            userId: umap.get("userId")?.val, 
                            role: umap.get("role")?.val,
                            name: umap.get("name")?.val,
                            email: umap.get("email")?.val,
                            region: umap.get("region")?.val,
                            phone: umap.get("phone")?.val,
                            notiSms: umap.get("notiSms")?.val,
                            notiMail: umap.get("notiMail")?.val,
                            belong: umap.get("belong")?.val,
                            approved: umap.get("approved")?.val,
                            created: umap.get("created")?.val,
                            lastAccess: umap.get("lastAccess")?.val, 
                            deleted: umap.get("deleted")?.val, 
                            loginIp: umap.get("loginIp")?.val,
                            salt: umap.get("salt")?.val
                        };
                        uList.push(user);
                    }
                }
                setUsers(uList);
            }, 
            (err) => {
                toast.current.show({ severity: 'error', summary: 'Failed to fetch users list', detail: err, life: 10000 });
            }
        );


    }, [appState.mmurl, tick]);

    const selectUser = (row) => {
        setSelectedUser(row);
        //props.onSelected(row);  // 상위로 전달하여 아래 Card의 이름을 바꾼다. 
    }

    const activateUser = (row) => {
        let taskUrl = appState.mmurl + `/obix/admin/updateUser`;
        console.dir(row);
        let active = !row.approved; // === "true" ? false : true;
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'id', val: row.userId },
                { o: 'bool', name: 'approved', val: active },
            ]
        }
        ObixUtil.invokeObix(taskUrl, body, 
            (resp) => {
                toast.current.show({ severity: 'success', summary: "Update User", detail: 'User state changed' });
                setTick(Date.now);
            }, 
            (err) => {
                toast.current.show({ severity: 'error', summary: `Failed to update user`, detail: err });
            }
        );
    }

    const subscribeUser = (row) => {
        let adminUrl = appState.mmurl + `/obix/admin/updateUser`;
        console.dir(row);
        let notiMail = !row.notiMail; // === "true" ? false : true;
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'id', val: row.userId },
                { o: 'bool', name: 'notiMail', val: notiMail },
            ]
        }
        ObixUtil.invokeObix(adminUrl, body, 
            (resp) => {                
                toast.current.show({ severity: 'success', summary: "Update User", detail: 'User state changed' });
                setTick(Date.now);
            }, 
            (err) => {
                toast.current.show({ severity: 'error', summary: `Failed to update user`, detail: err });
            }
        );
    }

    const nameBodyTemplate = (rowData) => {
        if (rowData.approved === true) {
            return <span><i className="pi pi-circle-fill" style={{color:"mediumspringgreen"}}/> {rowData.userId}</span>;
        } else {
            return <span><i className="pi pi-angle-right" style={{color:"gray"}}/> {rowData.userId}</span>;
        }
    }
    
    const subscribeTemplate = (rowData) => {
        return <span>{rowData.notiMail === true ? "Yes" : "No"}</span>;
    }

    const createdTemplate = (rowData) => {
        let d = new Date(rowData.created);
        return <span>{d.toLocaleDateString()}</span>;
    }

    const lastAccessTemplate = (rowData) => {
        if (rowData.lastAccess) {
            let d = new Date(rowData.lastAccess);
            return <span>{d.toLocaleString()}</span>;
        } else {
            return <span>-</span>
        }
    }

    const missingParam = (field) => {
        //toast.current.show({severity: 'warning', summary: 'Missing Information', detail: `${field} is required`});
        setMessage(`${field} is required.`);
        setShowMessage("");  
    }
    // Create New User 버튼을 눌렀을 때 
    const onCreateUser = () => {
        let addUserUrl = appState.mmurl + `/obix/admin/addUser`;
        if (!userId) {
            missingParam("User ID");
            return;
        }
        if (!name) {
            missingParam("Name");
            return;
        }
        if (!email) {
            missingParam("Email address");
            return;
        }
        if (!role) {
            missingParam("Role");
            return;
        }
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "id", val: userId.trim() },
                { o: "str", name: "name", val: name.trim() },
                { o: "str", name: "role", val: role.trim() },
                { o: "str", name: "email", val: email.trim() },
            ]
        }
        ObixUtil.invokeObix(addUserUrl, body,
            (resp) => {
                const pwd = ObixUtil.find(resp.data, "pwd")?.val;                    
                toast.current.show({ severity: 'success', summary: 'User created successfully', detail: `User ${userId} was created with ${pwd}`, life:30000 });
                setShowNew(false);
                setTick(Date.now);
                //props.forceUpdate();
            },
            (err) => {
                toast.current.show({ severity: 'error', summary: 'Failed to create user', detail: err, life: 10000 });
            }
        );  
    }

    // Create New User 버튼을 눌렀을 때 
    const onUpdateUser = () => {
        let updateUserUrl = appState.mmurl + `/obix/admin/updateUser`;
        if (!userId) {
            missingParam("User ID");
            return;
        }
        if (!name) {
            missingParam("Name");
            return;
        }
        if (!email) {
            missingParam("Email address");
            return;
        }
        if (!role) {
            missingParam("Role");
            return;
        }
        console.log(`region=${region}`);
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "id", val: userId },
                { o: "str", name: "name", val: name },
                { o: "str", name: "role", val: role },
                { o: "str", name: "email", val: email },
                { o: "str", name: "region", val: region },
                { o: "str", name: "phone", val: phone },
            ]
        }
        ObixUtil.invokeObix(updateUserUrl, body,
            (resp) => {
                toast.current.show({ severity: 'success', summary: 'User updated successfully', detail: ``, life:3000 });
                setShowNew(false);
                setTick(Date.now);
                //props.forceUpdate();
            },
            (err) => {
                toast.current.show({ severity: 'error', summary: 'Failed to update user', detail: err, life: 10000 });
            }
        );  
    }

    const onCreateOrUpdateUser = () => {
        if (editMode === true) {
            onUpdateUser();
        } else {
            onCreateUser();
        }
    }

    const editUser = (row) => {
        setEditMode(true);
        setShowNew(true);
        setUserId(row.userId);
        setName(row.name);
        setRole(row.role);
        setEmail(row.email);
        setPhone(row.phone);
        setRegion(row.region);
    }

    const title = <span>
        <h5 className="p-m-0"><i className="pi pi-users"></i> Manage Users</h5>
    </span>;

    return (
        <Card title={title}>
            <DataTable value={users} resizableColumns columnResizeMode="fit" showGridlines autoLayout={true} responsiveLayout="scroll"
                selectionMode="single" selection={selectedUser} onSelectionChange={e => selectUser(e.value)} 
                contextMenuSelection={selectedContext} onContextMenuSelectionChange={e => setSelectedContext(e.value)}
                onContextMenu={e => cm.current.show(e.originalEvent)}>
                <Column header="Name" body={nameBodyTemplate} field="userId"/>
                <Column field="role" header="Role" />
                <Column field="region" header="Regions" />
                <Column field="name" header="Name" />
                <Column field="email" header="Email" />
                <Column field="phone" header="Phone" />
                <Column field="notiMail" header="Noti" body={subscribeTemplate}/>
                {/*<Column field="team" header="Team" />*/}
                <Column field="created" header="Created" body={createdTemplate}/>
                <Column field="lastAccess" header="Last Access" body={lastAccessTemplate}/>
                <Column field="loginIp" header="Login IP" />
                {/*<Column field="last" header="Last"/>*/}
            </DataTable>
            <Dialog header={editMode ? "Change User Properties" : "Create New User"} visible={showNew} style={{ width: '500px' }} footer={renderFooter} onHide={() => setShowNew(false)}>
                <div className="grid mt-2">
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="userId" className="width-100" value={userId} onChange={(e) => setUserId(e.value)} readOnly={editMode===true}/>
                            <label htmlFor="userId">User ID</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="name" className="width-100" value={name} onChange={(e) => setName(e.target.value)} />
                            <label htmlFor="name">Name</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="email" className="width-100" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email">Email</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="phone" className="width-100" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <label htmlFor="phone">Phone</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <Dropdown id="role" className="width-100" value={role} options={roleItems} onChange={(e) => setRole(e.value)} placeholder="Select a Role"/>
                            <label htmlFor="role">Role</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="region" className="width-100" value={region} onChange={(e) => setRegion(e.target.value)}/>
                            <label htmlFor="region">Region</label>
                        </span>
                    </div>
                 </div>
                <Message severity="warn" text={message} className={showMessage}/>
            </Dialog>
            <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedContext(null)}/>
            <Toast ref={toast}/>
        </Card>
    );
}