import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import PasswordChecklist from "react-password-checklist"
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
//import { AppContext } from '../index';
import { AppStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { ObixUtil } from '../service/ObixUtil';
import { AppLogoEntry } from '../index';
import axios from 'axios';

export const SignUp = () => {
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [belong, setBelong] = useState("");
    //const [passwordValidated, setPasswordValidated] = useState(false);
    const toast = useRef(null);

    const goDashboard = () => {
        navigate('/');
    };

    const signUp = (e) => {
        console.log("Called login");
        let invokeUrl = appState.mmurl + "/obix/about/signUp";
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "id", val: userId }, 
                { o: "str", name: "password", val: password },
                { o: "str", name: "password2", val: password2 },
                { o: "str", name: "name", val: name },
                { o: "str", name: "email", val: email },
                { o: "str", name: "phone", val: phone },
                { o: "str", name: "belong", val: belong }
            ]
        }

        axios.post(invokeUrl, body, { headers: { 'X_HTTP_METHOD_OVERRIDE': 'POST' } })
            .then((resp) => {
                console.log(JSON.stringify(resp.data));
                let errmsg = ObixUtil.getErrorMsg(resp.data);
                if (errmsg) {
                    toast.current.show({ severity: 'error', summary: 'Failed to sign up', detail: errmsg, life: 10000 });                    
                } else {
                    //toast.current.show({ severity: 'success', summary: 'Sign Up request has been received ', detail: "Your request will be processed within 24 hours at the latest." });
                    confirmDialog({
                        message: 'Your account creation request has been received.\nIt will be processed within 24 hours and you will be notified by email.',
                        header: 'Request Accepted',
                        icon: 'pi pi-user-plus',
                        acceptLabel: "OK",
                        rejectClassName: "hidden",
                        accept: () => navigate('/login'),
                        reject: () => navigate('/login')
                    });
            
                    //navigate('/login');
                }
            }).catch((error) => {
                let errmsg = error.response?.status + "=>" + error.message;
                toast.current.show({ severity: 'error', summary: 'Failed to sign up', detail: errmsg, life: 3000 });
            });
    }

    return (
        <div className="pages-body login-page flex flex-column">
            <div className="topbar p-3 flex justify-content-between flex-row align-items-center">
                <div className="topbar-left ml-3 flex">
                    <AppLogoEntry/>                    
                </div>
                <div className="topbar-right mr-3 flex">
                    <Button onClick={goDashboard} type="button" label="DASHBOARD" className="p-button-text p-button-plain topbar-button"></Button>
                </div>
            </div>

            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <div className="pages-header px-3 py-1">
                        <h2>Sign-Up</h2>
                    </div>

                    <h4>Welcome</h4>

                    <div className="pages-detail mb-6 px-6">Please use the form to sign-up</div>
                    <div className="input-panel grid px-3" style={{width:"600px"}}>
                        <div className="p-inputgroup col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup1" value={userId} onChange={(e) => setUserId(e.target.value)}/>
                                <label htmlFor="inputgroup1">User ID</label>
                            </span>
                        </div>
                        <div className="col-6">

                        </div>
                        <div className="p-inputgroup mt-3 col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="password" id="inputgroup2" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <label htmlFor="inputgroup2">Password</label>
                            </span>
                        </div>
                        <div className="p-inputgroup mt-3 col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-unlock"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="password" id="inputgroup3" value={password2} onChange={(e) => setPassword2(e.target.value)}/>
                                <label htmlFor="inputgroup3">Confirm Password</label>
                            </span>
                        </div>
                        <div className="p-inputgroup mt-3 col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-tag"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroupName" value={name} onChange={(e) => setName(e.target.value)}/>
                                <label htmlFor="inputgroupName">Name</label>
                            </span>
                        </div>
                        <div className="p-inputgroup mt-3 col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup4" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                <label htmlFor="inputgroup4">E-Mail</label>
                            </span>
                        </div>
                        <div className="p-inputgroup mt-3 mb-6 col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-phone"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup5" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                <label htmlFor="inputgroup5">Cell Phone</label>
                            </span>
                        </div>
                        <div className="p-inputgroup mt-3 mb-6 col-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-sitemap"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup6" value={belong} onChange={(e) => setBelong(e.target.value)}/>
                                <label htmlFor="inputgroup6">Company</label>
                            </span>
                        </div>
                    </div>
                    <Button className="login-button mb-6 mx-3" label="Request an Account Creation" onClick={signUp}></Button>
                </div>
            </div>
            <ConfirmDialog />
            <Toast ref={toast}></Toast>
        </div>
    );
    
};

