import { atom } from 'recoil';

export const AuthStateAtom = atom({
    key: 'AuthStateAtom', // unique ID (with respect to other atoms/selectors)
    default: {
        user: null,
        role: null,
        isAdmin: false,
        isUser: false,
        authenticated: false
    }, // default value (aka initial value)
});

export const RtlAtom = atom({
    key: 'RtlAtom', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
});

export const ColorModeAtom = atom({
    key: 'ColorModeAtom',
    effects: [
        ({ setSelf, onSet }) => {  // 초기값 설정 
            let cm = window.localStorage['mmColorMode'];
            console.log(`in atom, colorMode=${cm}`);
            if (cm !== 'light' && cm !== 'dark') {
                cm = 'light';
            }
            setSelf(cm);
            onSet(newSet => {
                console.log(`new colorMode = ${newSet}`);
                window.localStorage['mmColorMode'] = newSet;
            });
        }
    ]
});

export const AppStateAtom = atom({
    key: 'AppStateAtom', // unique ID (with respect to other atoms/selectors)
    default: {
        mmurl: null,
        brand: null,
        edge: null,
    }, // default value (aka initial value)
    effects: [
        ({ setSelf, onSet }) => {  // 초기값 설정 
            let url = window.localStorage['mmurl'];
            if (!url || url.length < 5) {
                url = window.location.protocol + "//" + document.location.host;
            }    
            setSelf({
                mmurl: url,
                brand: null,
                edge: null,
            });
            onSet(newSet => {
                console.log(`new mmurl ${newSet?.mmurl}`);
            });
        }
    ]
});

// 선택된 Site ID 저장. 예전 region 
export const SiteIdAtom = atom({
    key: 'SiteIdAtom',
    default: window.localStorage["mm_site"],
    effects: [
        ({ onSet }) => {  // 이 State가 변할때 마다 호출 
            onSet(newSite => {
                console.log("Site changed to:", newSite);
                window.localStorage["mm_site"] = newSite;
            });
        },
    ]
});

export const SiteInfoAtom = atom({
    key: 'SiteInfoAtom',
    default: {
        id: null
    }
});  // getSiteInfo에서 가져오는 것임. 