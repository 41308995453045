import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { BatteryHorizIcon, GensetIcon, HvacIcon, ImdIcon, MeterIcon, PcsIcon, PvIcon, IconLabel, HorizLine, Horiz90Line, ElecLine, LoadIcon } from '../service/SvgIcons';
import { ObixUtil } from '../service/ObixUtil';

export function SingleLineSvgBvr(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const ix0 = 100;
    const ix1 = 630;
    const ix2 = 820;
    const iy0 = 5;
    const iy1 = 135;
    const iy2 = 265;
    const iy3 = 395;
    const iw = 75;  // icon width
    const ih = 75;  // icon height
    const jx = 430;  // joint x
    const jy = (iy0+iy3+ih)/2;
    const fs = 20;  // font size
    
    const [pcsObj1, setPcsObj1] = useState(null);
    const [pcsObj2, setPcsObj2] = useState(null);
    const [pvObj1, setPvObj1] = useState(null);
    const [pvObj2, setPvObj2] = useState(null);
    const [batObj1, setBatObj1] = useState(null);
    const [batObj2, setBatObj2] = useState(null);
    const [meterObj, setMeterObj] = useState(null);

    useEffect(() => {
        //console.dir(props.objmap);
        if (!props?.objmap) return;
        setPcsObj1(props.objmap.get('pcs1/F0/pcs'));
        setPcsObj2(props.objmap.get('pcs2/F0/pcs'));
        setPvObj1(props.objmap.get('pv/1/inverter'));
        setPvObj2(props.objmap.get('pv/2/inverter'));
        setBatObj1(props.objmap.get('bms1/1/bms'));
        setBatObj2(props.objmap.get('bms2/1/bms'));
        setMeterObj(props.objmap.get('meter/1/meter/ch1'));
    }, [props.objmap]);

    return (
        <svg width="100%" height="100%" viewBox="0 0 1024 526">
            <g>
                <ElecLine path={[ix1, iy0+ih/2, jx, iy0+ih/2, jx, jy]} obj={pcsObj1}/>
                <PcsIcon x={ix1} y={iy0} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy0+ih+fs} h={fs} type="ac" obj={pcsObj1}/>
                <ElecLine path={[ix2, iy0+ih/2, ix1+iw, iy0+ih/2]} obj={pcsObj1}/>
                <BatteryHorizIcon x={ix2} y={iy0} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy0+ih+fs} h={fs} type="bat" obj={batObj1}/>

                <ElecLine path={[ix1, iy1+ih/2, jx, iy1+ih/2, jx, jy]} obj={pcsObj2}/>
                <PcsIcon x={ix1} y={iy1} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy1+ih+fs} h={fs} type="ac" obj={pcsObj2}/>
                <ElecLine path={[ix2, iy1+ih/2, ix1+iw, iy1+ih/2]} obj={pcsObj2}/>
                <BatteryHorizIcon x={ix2} y={iy1} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy1+ih+fs} h={fs} type="bat" obj={batObj2}/>

                <ElecLine path={[ix1, iy2+ih/2, jx, iy2+ih/2, jx, jy]} obj={pvObj1}/>
                <PcsIcon x={ix1} y={iy2} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy2+ih+fs} h={fs} type="ac" obj={pvObj1}/>
                <ElecLine path={[ix2, iy2+ih/2, ix1+iw, iy2+ih/2]} obj={pvObj1}/>
                <PvIcon x={ix2} y={iy2} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy2+ih+fs} h={fs} type="pv" obj={pvObj1}/>

                <ElecLine path={[ix1, iy3+ih/2, jx, iy3+ih/2, jx, jy]} obj={pvObj1}/>
                <PcsIcon x={ix1} y={iy3} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy3+ih+fs} h={fs} type="ac" obj={pvObj2}/>
                <ElecLine path={[ix2, iy3+ih/2, ix1+iw, iy3+ih/2]} obj={pvObj1}/>
                <PvIcon x={ix2} y={iy3} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy3+ih+fs} h={fs} type="pv" obj={pvObj2}/>

                <MeterIcon x={ix0} y={jy-ih/2} w={iw} h={ih}/>
                <ElecLine path={[ix0+iw, jy, jx, jy]} obj={meterObj} power="+power"/>
                <IconLabel x={ix0} y={jy+ih/2+fs} h={fs} type="ac" obj={meterObj}/> 
                <ElecLine path={[0, jy, ix0, jy]} obj={meterObj} power="+power"/>

                <LoadIcon x={260} y={jy-ih/2+130} w={iw} h={ih} />
                <ElecLine path={[260+iw/2, jy-ih/2+130, 260+iw/2, jy]} dir="in"/>
            </g>
        </svg>
    );
}
